
// Define only SASS variables in this file.

$theme-colour: #444444;


body {
    font-family: 'Quicksand', sans-serif;
  }
  
  .gal-container {
    padding: 12px;
  }
  
  .gal-item {
    overflow: hidden;
    padding: 3px;
  }
  
  .gal-item .box {
    height: 350px;
    overflow: hidden;
  }
  
  .box img {
    height: 110%;
    width: 110%;
    top: -10%;
    object-fit: cover;
    -o-object-fit: cover;
    -webkit-transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
  }
  
  .box:hover img{
    transform: scale(1.1);
    -webkit-transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
  }
  
  .gal-item a:focus {
    outline: none;
  }
  
  .gal-item a{
    cursor: pointer;
  }
  
  .gal-item a::after {
    content: "\f00e";
    font-family: "FontAwesome";
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
    right: 3px;
    left: 3px;
    top: 3px;
    bottom: 3px;
    text-align: center;
    line-height: 350px;
    font-size: 30px;
    color: #fff;
    -webkit-transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
  }
  
  .gal-item a:hover:after {
    opacity: 1;
  }
  
  .modal-open .gal-container .modal {
    background-color: rgba(0,0,0,0.4);
  }
  
  .modal-open .gal-item .modal-body {
    padding: 0px;
  }
  
  .modal-open .gal-item button.close {
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #000;
    opacity: 1;
    color: #fff;
    z-index: 999;
    right: -12px;
    top: -12px;
    border-radius: 50%;
    font-size: 15px;
    border: 2px solid #fff;
    line-height: 25px;
    -webkit-box-shadow: 0 0 1px 1px rgba(0,0,0,0.35);
    box-shadow: 0 0 1px 1px rgba(0,0,0,0.35);
  }
  
  .modal-open .gal-item button.close:focus {
    outline: none;
  }
  
  .modal-open .gal-item button.close span {
    position: relative;
    top: -3px;
    font-weight: lighter;
    text-shadow: none;
  }
  
  .gal-container {
    width: 80%;
  }
  
  
  @media (min-width: 768px) {
    .gal-container .modal-dialog {
      width: 55%;
      margin: 50 auto;
    }
  }
  
  @media (max-width: 768px) {
    .gal-container .modal-content {
      height: 250px;
    }
  }
  
  
  /* Modal */
  #myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  #myImg:hover {opacity: 0.7;}
  
  
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.9); 
  }
  
  .modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
  }
  
  #caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }
  
  .modal-content, #caption { 
    animation-name: zoom;
    animation-duration: 0.6s;
  }
  
  @keyframes zoom {
    from {transform:scale(0)} 
    to {transform:scale(1)}
  }
  
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  
  
  @media only screen and (max-width: 700px){
    .modal-content {
      width: 100%;
    }
  }